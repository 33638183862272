import {
  CONTACTS_LIST,
  CONTACTS_FILTER,
  CONTACTS_CREATE,
  CONTACTS_CREATE_SUCCESS,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_REQUEST_FAILED,
  CONTACTS_UPDATE,
  CONTACTS_UPDATE_SUCCESS,
  CONTACTS_DELETE,
  CONTACTS_DELETE_SUCCESS,
} from "../contacts/actionTypes";

const initialState = {
  contacts: [],
  contactsCreated: {},
  contactsUpdated: {},
  contactsDeleted:false,
  loading: false,
  error: "",
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_DELETE:
        state = {
          ...state,
          loading: true,
          contactsDeleted: false,
        };
        break;
    case CONTACTS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsCreated: action.payload,
        error: "",
      };
      break;
    case CONTACTS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        contacts: action.payload,
        contactsCreated: {},
        contactsDeleted: false,
        error: "",
        next: action.payload.next,
      };
      break;


    case CONTACTS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsUpdated: action.payload,
        error: "",
      };
      break;
    case CONTACTS_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsDeleted: true,
        error: "",
      };
      break;
    case CONTACTS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contacts;
