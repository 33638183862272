import React from "react";
import { Modal, ModalBody, ModalFooter, Row, Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const ChartsModal = ({ isOpen, toggle, managementData }) => {
  const calculatePercentage = (value) => {
    return managementData.total_contactos > 0 ? (value / managementData.total_contactos) * 100 : 0;
  };

  const radialBarOptions = (label, value, percentage, color) => ({
    chart: {
      height: 300,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '55%',
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '16px',
          },
          value: {
            show: true,
            fontSize: '18px',
            fontWeight: 'bold',
            formatter: function (val) {
              return `${Math.round(val) || 0}%`; // Muestra el porcentaje
            },
          },
          total: {
            show: true,
            label: label,
            formatter: function () {
              return `${value || 0} (${Math.round(percentage) || 0}%)`; // Muestra el número y el porcentaje
            },
          },
        },
      },
    },
    colors: [color],
    legend: {
      show: true,
      position: 'bottom',
    },
    labels: [label], // Especifica la etiqueta para la leyenda
  });

  const pastelColors = ['#56A75F', '#87CEFA', '#FFB74D', '#BA55D3', '#228B22'];

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalBody>
        <Row>
          {/* Exitosos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <ReactApexChart
                  options={radialBarOptions(
                    'Exitosos',
                    managementData.exitosos,
                    calculatePercentage(managementData.exitosos),
                    pastelColors[0] // Color específico
                  )}
                  series={[calculatePercentage(managementData.exitosos)] || [0]} // Muestra 0% si no hay datos
                  type="radialBar"
                  height="300"
                />
              </CardBody>
            </Card>
          </Col>
          {/* Fallidos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <ReactApexChart
                  options={radialBarOptions(
                    'Fallidos',
                    managementData.fallidos,
                    calculatePercentage(managementData.fallidos),
                    pastelColors[1] // Color específico
                  )}
                  series={[calculatePercentage(managementData.fallidos)] || [0]} // Muestra 0% si no hay datos
                  type="radialBar"
                  height="300"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          {/* Sin Teléfono */}
          <Col md={6}>
            <Card>
              <CardBody>
                <ReactApexChart
                  options={radialBarOptions(
                    'Sin Teléfono',
                    managementData.sin_telefono,
                    calculatePercentage(managementData.sin_telefono),
                    pastelColors[2] // Color específico
                  )}
                  series={[calculatePercentage(managementData.sin_telefono)] || [0]} // Muestra 0% si no hay datos
                  type="radialBar"
                  height="300"
                />
              </CardBody>
            </Card>
          </Col>
          {/* Múltiples Teléfonos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <ReactApexChart
                  options={radialBarOptions(
                    'Múltiples Teléfonos',
                    managementData.multiples_telefonos,
                    calculatePercentage(managementData.multiples_telefonos),
                    pastelColors[4] // Color específico
                  )}
                  series={[calculatePercentage(managementData.multiples_telefonos)] || [0]} // Muestra 0% si no hay datos
                  type="radialBar"
                  height="300"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          {/* Total Contactos */}
          <Col md={12}>
            <Card>
              <CardBody>
                <ReactApexChart
                  options={radialBarOptions(
                    'Total de Contactos',
                    managementData.total_contactos,
                    100, // Siempre 100% para total contactos
                    pastelColors[3] // Color específico
                  )}
                  series={[100]} // El 100% para total contactos
                  type="radialBar"
                  height="300"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="text-dark" onClick={toggle}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChartsModal;
