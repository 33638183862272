import React from "react";
import MetaTags from "react-meta-tags";
import { Container,Row, Col } from "reactstrap";
import Widget from './components/Widget';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { SideComponent } from "./components/SideComponent";
import ReportOnBot from "./components/ReportOnBot";
import ReportForHour from "./components/ReportForHour";
import { AgentsState } from "./components/AgentsState";
import ContactsInManagement from "./components/ContactsInManagement";

const Dashboard = () => {

  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups[0][0]);
  console.log(groups)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Dashboard"} />
          <Row>
            <Col lg={9}>
              <Row>
                <Col>
                  <Widget />
                </Col>
              </Row>
              <Row>
                <Col lg= {12}>
                  <ContactsInManagement />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <ReportOnBot />
                </Col>
                <Col lg={6}>
                  <ReportForHour />
                </Col>
              </Row>

            </Col>
            <Col lg={3}>
              {
                groups === 'SuperAdmin' || groups === 'Administrador' || groups === 'Supervisor' ? (<AgentsState />) : null
              }
              <SideComponent />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
