import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getContactsList, getContactsFilter } from "../helpers/backend";

import PaginationTest from "./PaginationTest";


const ContactsSelect = (props) => {
  const [contacts, setContacts] = useState([]);
  const [contactsFull, setContactsFull] = useState([]);
  const [next, setNext] = useState(null);
  const [inputSearch, setInputSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [triggerPagination, setTriggerPagination] = useState(false);

  const getContactData = async () => {
    try {
      const response = await getContactsList();
      setContacts(response.data.results);
      setNext(response.data.next);
    } catch (error) {
      console.error("Error al obtener los contactos:", error);
    }
  };

  const handleFilter = async (params) => {
    try {
      const response = await getContactsFilter(params);
      if (response && response.data) {
        setContacts(response.data.results);
        setNext(response.data.next);
        setTriggerPagination(false);
      }
    } catch (error) {
      console.error("Error en handleFilter:", error);
    }
  };

  const onSearch = (searchTerm) => {
    setInputSearch(true);
    setSearchTerm(searchTerm);
    handleFilter({ changeUrl: `?search=${searchTerm}` });
  };

  useEffect(() => {
    getContactData();
  }, []);

  useEffect(() => {
    if (Array.isArray(contacts) && !inputSearch) {
      setContactsFull((prevContacts) => [
        ...prevContacts,
        ...contacts.filter(
          (contact) => !prevContacts.some((c) => c.id === contact.id)
        ),
      ]);
    }
    if (inputSearch) {
      setContactsFull(contacts);
    }
  }, [contacts, inputSearch]);

  return (
    <React.Fragment>
      {triggerPagination && next && (
        <PaginationTest
          next={next}
          filterFunction={handleFilter}
          setSearchTerm={setSearchTerm}
        />
      )}
      <Select
        options={
          contactsFull &&
          contactsFull.map((item) => ({
            label: item.get_full_name,
            value: item.id,
          }))
        }
        isMulti
        placeholder="Seleccione contactos"
        onMenuScrollToBottom={() => {
          setTriggerPagination(true);
        }}
        menuPlacement="auto"
        onChange={(selectedOptions) => {
          const selectedContactData = selectedOptions.map((option) => option.value);
          console.log("Contactos seleccionados:", selectedContactData);
       
          if (props.onContactSelect) {
            props.onContactSelect(selectedContactData);
          }
        }}
        onInputChange={(inputValue) => {
          onSearch(inputValue);
          if (inputValue === "") {
            setInputSearch(false);
          } else {
            setInputSearch(true);
          }
        }}
      />
    </React.Fragment>
  );
};

export default ContactsSelect;
