import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages

import Login from "../pages/Authentication/Login";


// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";

//Administration
import UserList from "../pages/Administration/Users/UserList";
import UserAdd from "../pages/Administration/Users/UserAdd";
import UserAddPublic from "../pages/Administration/Users/UserAddPublic";
import UserEdit from "../pages/Administration/Users/UserEdit";
import UserPermissions from "../pages/Administration/Users/UserPermissions";
import UserProfile from "../pages/Administration/Users/UserProfile";
import UserChangePassword from "../pages/Administration/Users/ChangePassword";

import AgentAssign from "../pages/Roles/AgentAssign";
import RolUpdate from "../pages/Roles/RolUpdate";
import RolCreate from "../pages/Roles/RolCreate";
import PermissionAssign from "../pages/Roles/PermissionAssign";

//Contacts
import ContactsIndex from "../pages/Contacts";
import Contact from "../pages/Contacts/Contact";
import ContactsCreate from "../pages/Contacts/ContactAdd";
import CompanyCreate from "../pages/Contacts/CompanyAdd"
import ProfileTab from "../pages/Contacts/components/ProfileTab";
import CalendarContact from "../pages/Calendar/Calendar";

//Gestion contacto 
import CalificacionContacto from "../pages/CalificaciónContacto/CalificacionContcacto"
import DescripcionCalificacion from "../pages/DescripcionCalificacion/DescripcionCalificacion"
import CalificacionContactoAdd from "../pages/CalificaciónContacto/CalificacionContactoAdd"
import DescripcionCalificacionAdd from "../pages/DescripcionCalificacion/DescripcionCalificacionAdd"
import GestionContacto from "../pages/GestionContacto/GestionContacto";
import GestionContactoCreate from "../pages/GestionContacto/GestionContactoCreate"
//Account
import Account from "../pages/Account/Account";
import AccountCreate from "../pages/Account/AccountCreate";
import AccountUpdate from "../pages/Account/AccountUpdate";
// Campaigns
import Campaign from "../pages/Campaign/Campaign";
import CampaignCreate from "../pages/Campaign/CampaignCreate";
import CampaignUpdate from "../pages/Campaign/CampaignUpdate";
//Leads
import Leads from "../pages/Leads/Leads";
import LeadsCreate from "../pages/Leads/LeadsCreate";
import LeadsUpdate from "../pages/Leads/LeadsUpdate";

//Casos
import Casos from "../pages/Casos/Casos";
import CasosCreate from "../pages/Casos/CasosCreate";
import CasosUpdate from "../pages/Casos/CasosUpdate";

//Error
import  Error404  from "../pages/Error/Error404"

// Rutas Publicas
import Prueba from "../pages/Public/Prueba"
 
import Audits from "../pages/Audits/AuditsList"
import TipoGestionContacto from "../pages/TipoGestionContacto/TipoGestionContacto";
import TipoGestionContactoAdd from "../pages/TipoGestionContacto/TipoGestionContactoAdd";

//Survey
import SurveyCreate from "../pages/Survey/SurveyCreate";
import Survey from "../pages/Survey/Survey";

//Whatsaap
import { Index } from "../pages/Whatsapp/Index";
import Chat from "../pages/Chat/Chat";
import CasosDetails from "../pages/Casos/CasosDetails";
import ManagementList from "../pages/GestionContacto/ManagementList";
import ExcelMasive from "../pages/Modales/ExcelMasive";


import IssabelReport from "../pages/Issabel/IssabelReport";
import ReportsForHour from "../pages/Reports/ReportsForHour";
import ReportSupervisor from "../pages/Reports/ReportSupervisor";
import ReportsBot from "../pages/Reports/ReportsBot";
import ReportForTime from "../pages/Reports/ReportForTime";
import LandingPage from "../pages/LandingPage/LandingPage";
import ContactsGroups from "../pages/ContactsGroups/ContactsGroups";
import Templates from "../pages/Templates/Templates";
import BroadcastCampaign from "../pages/BroadcastCampaign/BroadcastCampaign";


const publicRoutes = [
  { path: "/prueba", component: Prueba },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/users/add_user2", component: UserAddPublic },
  { path: "/landingPage", component: LandingPage },
  
];

const allRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: UserList },
  { path: "/users/add_user", component: UserAdd },
  { path: "/users/:id/edit_user", component: UserEdit },
  { path: "/userProfile", component: UserProfile },
  { path: "/changePassword", component: UserChangePassword },
  { path: "/audits", component: Audits },
  { path: "/users/permission_assign/:id", component: UserPermissions },
  { path: "/rol/permission_assign/:id", component: PermissionAssign },
  { path: "/rol/add_rol", component: RolCreate },
  { path: "/rol/agent_assign", component: AgentAssign },
  { path: "/rol/update_rol/:id", component: RolUpdate },
  { path: "/contact/add_contact", component: ContactsCreate },
  { path: "/contact/add_company", component: CompanyCreate },
  { path: "/contacts/:id/index", component: ContactsIndex },
  { path: "/contacts", component: Contact },
  { path: "/contact/:id/contact_profile", component: ProfileTab },
  { path: "/contact/calendar/:id", component: CalendarContact },
  { path: "/account", component: Account },
  { path: "/account/create", component: AccountCreate },
  { path: "/account/update/:id", component: AccountUpdate },
  { path: "/campaign", component: Campaign },
  { path: "/campaign/create", component: CampaignCreate },
  { path: "/campaign/update/:id", component: CampaignUpdate },
  { path: "/leads", component: Leads },
  { path: "/leads/create", component: LeadsCreate },
  { path: "/leads/update/:id", component: LeadsUpdate },
  { path: "/survey", component: Survey },
  { path: "/survey/create", component: SurveyCreate },
  { path: "/descripcionCalificacion", component: DescripcionCalificacion },
  { path: "/calificacionContacto", component: CalificacionContacto },
  { path: "/descripcionCalificacion/add_descripcion", component: DescripcionCalificacionAdd },
  { path: "/calificacionContacto/add_calificacion", component: CalificacionContactoAdd },  
  { path: "/casos", component: Casos },
  { path: "/casos/create", component: CasosCreate },
  { path: "/casos/update/:id", component: CasosUpdate },
  { path: "/casos/view/:id", component: CasosDetails },
  { path: "/chat/:id", component: Index },
  { path: "/chat/", component: Index },
  { path: "/chatMasive/", component: ExcelMasive },
  { path: "/chatWeb/", component: Chat },
  { path: "/issabelReport", component: IssabelReport },

  { path: "/tipoGestionContacto", component: TipoGestionContacto },
  { path: "/tipoGestionContacto/add_tipoGestion", component: TipoGestionContactoAdd },
  { path: "/gestionContactoCreate/:id", component: GestionContactoCreate }, 
  { path: "/gestionContacto/:id", component: GestionContacto }, 
  { path: "/managementList", component: ManagementList }, 
  { path: "/report_for_hour", component: ReportsForHour },
  { path: "/report_for_bot", component: ReportsBot },
  { path: "/report_for_time", component: ReportForTime },
  { path: "/report_supervision", component: ReportSupervisor },
  { path: "/contactsGroups", component: ContactsGroups },
  { path: "/templates", component: Templates },
  { path: "/boradcastCampaign", component: BroadcastCampaign },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "*",  component: Error404 },
];

export { publicRoutes, allRoutes, authRoutes };
