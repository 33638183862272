import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, CardBody, Table, Card, Button, Modal, FormGroup, Label, Input } from "reactstrap";
import Pagination from "../../components/Common/Pagination";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import {  getGrupoContacto, postGrupoAddContacto, postGrupoContacto,
   postGrupoExcel, postGrupoRemoveContacto, getContactsFilter , getContactsList,
   
  } from "../../helpers/backend";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { FaTag } from 'react-icons/fa';
import excelLogo from "../../assets/images/excel.png"
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Common/Loader";
import PaginationTest from '../../components/PaginationTest';
// import { contactsFilter,contactsList } from "../../store/contacts/actions";
// import {  useDispatch, useSelector } from "react-redux";

import ContactsSelect from "../../components/ContactsSelect";

const colorOptions = [
  { label: <span><FaTag style={{ color: 'blue', marginRight: 5 }} /> Azul</span>, value: 'blue' },
  { label: <span><FaTag style={{ color: 'orange', marginRight: 5 }} /> Naranja</span>, value: 'orange' },
  { label: <span><FaTag style={{ color: 'pink', marginRight: 5 }} /> Rosa</span>, value: 'pink' },
  { label: <span><FaTag style={{ color: 'yellow', marginRight: 5 }} /> Amarillo</span>, value: 'yellow' },
  { label: <span><FaTag style={{ color: 'green', marginRight: 5 }} /> Verde</span>, value: 'green' },
  { label: <span><FaTag style={{ color: 'purple', marginRight: 5 }} /> Morado</span>, value: 'purple' },
  { label: <span><FaTag style={{ color: 'gray', marginRight: 5 }} /> Otros</span>, value: 'gray' },
];


const ContactsGroups = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);

  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [nameGroups, setNameGroups] = useState(null);
  const [contactData, setContactData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupName, setGroupName] = React.useState('');
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(null)
  const [alert, setAlert] = useState(false)
  const [text, setText] = useState("")

  const [data, setData] = useState({})
  const getContactsData = async () => {
    const response = await getGrupoContacto();
    const data = response.data;
    setData(data);
  };
  const [contacts, setContacts] = useState({});
 
 
 
  useEffect(() => {

    getContactsData()
  }, [])


  console.log(data)






// console.log(state)
  const postExcel = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const response = await postGrupoExcel(formData)
    setLoading(false)
    setStatus(response.status)
    setText(response.data)
    setLoading(false)
    setAlert(true)
  };
  const postGroups = async () => {
    const response = await postGrupoContacto({
      name: groupName,
      contacts: selectedContact,
      etiqueta: selectedColor,
    })

    console.log(response);

    setSelectedContact(null)
    getContactsData()
    setModalOpen2(false)
  }
  const assignContacts = async () => {
    const selectedContactId = selectedContact.map(group => group.value);
    const response = await postGrupoAddContacto(groupId, {
      contacts: selectedContact
    })
    console.log(response);
    if (response.status === 200) {
      setSelectedContact(null) 
      setContactData(response.data.contactos_agregados)
      getContactsData()
    }
  }
  const handleRemoveContacts = async () => {
    const response = await postGrupoRemoveContacto(groupId, {
      contacts: selectedContactIds,
    })
    console.log(response);
    if (response.status === 200) {
      setSelectedContactIds("")
      setContactData(response.data.contactos_restantes)
      getContactsData()
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedContactIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleContactClick = (id) => {
    handleCheckboxChange(id);
  };

  const contactsArray = Array.isArray(contacts) ? contacts : [];
  const availableContacts = contactsArray && contactsArray.filter(contact =>
    !contactData.some(([_, id]) => id === contact.id)
  );
  const filteredContacts = contactData && contactData.filter(([name]) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <React.Fragment>

      {loading ? (
        <Loader />
      ): null}
      {status === 200 && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
            setSelectedFile("")
          }}
        >{text.resultado}
        </SweetAlert>
      ) : null}
      {status !== 200 && alert ? (
        <SweetAlert
          title="Error"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
          }}
        > {text.errores}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        centered={true}
        size="lg"
      >
        <h4 className="text-primary m-3">{nameGroups}</h4>
        <h6 className="p-2 text-dark">Agrega contactos al grupo</h6>
        <div className="px-3">
          <Row className="d-flex justify-content-center">
            <Row className="mb-2">
              <Col lg={12} className="mt-1">
                {/* <Select
                  style={{ minHeight: "100px" }}
                  options={availableContacts && availableContacts.map((item) => ({
                    label: item.get_full_name,
                    value: item.id,
                  }))}
                  onChange={(selectedOptions) => {
                    setSelectedContact(selectedOptions); // Guarda el objeto completo (label y value)
                  }}
                  value={selectedContact}
                  placeholder={"Seleccione"}
                  isMulti={true}
                  menuPosition="fixed"
                /> */}
                  <ContactsSelect
        filterFunction={getContactsFilter}
        paginationComponent={PaginationTest}
        onContactSelect={setSelectedContact}
      />
              </Col>
            </Row>
            {selectedContact ?
              <Row className="mt-3 p-2">
                <Col className="d-flex justify-content-center">
                  <Button
                    color="success"
                    className="btn btn-sm"
                    onClick={assignContacts}
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
              : null}
          </Row>
          <Row className="mt-3">
            <Col>
              <FormGroup>
                <Label>Contactos en el grupo:</Label>
                <Row className="mb-3">

                  <div className="d-flex align-items-center mb-2">
                    <Col lg={4} className=" me-2">
                      <Input
                        type="text"
                        placeholder="Buscar contactos..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="me-2 flex-grow-1"
                      />
                    </Col>                
                  </div>           
                </Row>
                <div
                  className="d-flex flex-wrap"
                  style={{
                    maxHeight: '300px', // Ajusta según sea necesario
                    overflowY: 'auto',
                  }}
                >
                  {filteredContacts && filteredContacts.map(([name, id]) => (
                    <div key={id} className="p-2" style={{ flexBasis: '25%' }}> {/* Ajusta el porcentaje según el número de columnas deseadas */}
                      <input
                        type="checkbox"
                        checked={selectedContactIds.includes(id)}
                        onChange={() => handleCheckboxChange(id)}
                        style={{ cursor: 'pointer' }}
                      />
                      <span
                        className="ms-2"
                        onClick={() => handleContactClick(id)}
                        style={{ cursor: 'pointer', textDecoration: selectedContactIds.includes(id) ? 'underline' : 'none' }}
                      >
                        {name}
                      </span>
                    </div>
                  ))}
                </div>
              </FormGroup>

            </Col>
          </Row>
        </div>

        {selectedContactIds.length > 0 && (
          <Row className="mt-3 p-2">
            <Col className="d-flex justify-content-center">
              <Button
                color="danger"
                className="btn btn-sm"
                onClick={handleRemoveContacts}
              >
                Remover
              </Button>
            </Col>
          </Row>
        )}

        <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-end">
            <Button
              className="text-dark btn btn-secondary"
              onClick={() => setModalOpen(false)}
            >
              Cerrar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(false)}
        centered={true}
        size="md"
      >
        <h4 className="text-primary m-3">Agregar nuevo Grupo de Contactos</h4>

        <div >
          <Row>
            <Row className="mb-2 d-flex justify-content-center">
              <Col lg={8} className="mt-1  ">
                <Input
                  type="text"
                  placeholder="Nombre del grupo"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-2 d-flex justify-content-center">
              <Col lg={8} className="mt-1  ">
                <Select
                  options={colorOptions}
                  onChange={(selectedOption) => setSelectedColor(selectedOption.value)}
                  placeholder={"Seleccione una etiqueta"}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
            <Row className="mb-2 d-flex justify-content-center">
              <Col lg={8} className="mt-1  ">
              {/* <Select
   options={contactsFull && contactsFull.map((item) => ({
    label: item.get_full_name,
    value: item.id,
  }))}// Opciones dinámicas
  isMulti
  placeholder="Seleccione contactos"
  onMenuScrollToBottom={()=> {
    setTriggerPagination(true)
  } }  // Detectar scroll al final del menú
  // isLoading={isLoadingContacts} // Indicador de carga
  menuPlacement="auto"
  onChange={(selectedOptions) => {
    const selectedContactData = selectedOptions.map((option) => option.value);
    console.log("Contactos seleccionados:", selectedContactData);
  }}
  onInputChange={(inputValue) => {
    onSearch(inputValue); // Llamamos a la función onSearch con el valor actual del input
    if (inputValue === "") {
      setInputSearch(false); // Si el valor está vacío, seteamos InputSearch a false
    } else {
      setInputSearch(true); // Si hay valor, seteamos InputSearch a true (opcional según necesidad)
    }
  }}
/> */}
  <ContactsSelect
        filterFunction={getContactsFilter}
        paginationComponent={PaginationTest}
        onContactSelect={setSelectedContact}
      />
              </Col>
            </Row>
            <Row className="mt-3 p-2  ">
              <Col className="d-flex justify-content-center">
                <Button
                  color="success"
                  className="btn btn-sm"
                  onClick={postGroups}
                >
                  Agregar
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
        <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-end">
            <Button
              className="text-dark btn btn-secondary"
              onClick={() => setModalOpen2(false)}
            >
              Cerrar
            </Button>
          </Col>
        </Row>
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Grupos de Contacto </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Grupos de Contacto"} />
          <div className="d-flex justify-content-center">
            <Card className="col-lg-10  ">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex mb-2 justify-content-start">
                      {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
                        <Link
                          className="btn btn-primary waves-effect waves-light me-1"
                          to="#"
                          onClick={() => setModalOpen2(true)}
                        >
                          Agregar Grupo{" "}
                        </Link>
                      ) : null}

                      <input
                        type="file"
                        id="excelFile"
                        accept=".xls, .xlsx"
                        style={{ display: 'none' }}
                        onChange={(event) => setSelectedFile(event.target.files[0])}
                      />
                      <Button
                        color="secondary"
                        className="btn d-flex align-items-center text-dark"
                        onClick={() => {
                          document.getElementById('excelFile').click();
                        }}
                      >
                        <img className="me-2" alt="Excel Logo" src={excelLogo} height="20px" />
                        <b>Subir Excel</b>
                      </Button>
                    </div>

                    {selectedFile && (
                      <p className="font-size-14 ms-2 mb-2 text-muted">
                        Archivo seleccionado: {selectedFile.name}
                      </p>
                    )}

                    {selectedFile && (
                      <div className="d-flex">
                        <Button
                          color="success"
                          className="me-2 btn-sm"
                          onClick={() => { setLoading(true); postExcel(); }}
                        >
                          Generar
                        </Button>
                        <Button
                          color="danger"
                          className="btn-sm"
                          onClick={() => {
                            setSelectedFile(null);
                          }}
                        >
                          Borrar
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </div>
          <div className="d-flex justify-content-center">
            <Card className="col-lg-10  ">
              {data.results ? (
                <CardBody >
                  <h2 className="mb-3 font-size-20">Grupo de Contactos</h2>
                  <div className="table-responsive">
                    <Table
                      className="table  align-middle table-centered table-nowrap mb-3"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Etiqueta</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.results.map((item, idx) => (
                          <tr key={idx}>
                            <th >{idx + 1}</th>
                            <td >{item.name}</td>
                            <td >      <i
                              className="fas fa-tag fa-lg"
                              style={{ color: item.etiqueta }}
                              title="Etiqueta"
                            ></i></td>
                            <td>
                              <Link to={`#`} onClick={() => { setModalOpen(true); setNameGroups(item.name); setContactData(item.contacts); setGroupId(item.id) }}>
                                <span className="fas fa-users fa-lg align-middle"></span>
                              </Link>{" "}</td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    count={data.count}
                    next={data.next}
                    previous={data.previous}
                    limit={30}
                    baseUrl="contact/grupoContacto/"
                    filterFunction={''}
                  />
                </CardBody>
              ) : (
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
              )}
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsGroups;
