import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Toast,
  ToastBody,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// Import Breadcrumb
import Loader from "../../components/Common/Loader";
import Pagination from "../../components/Common/Pagination_ansync";
import excelLogo from "../../assets/images/excel.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getBroadcastCampaign, getBroadcastCampaignFilter, postBroadcastConfirmation } from "../../helpers/backend";
import BroadcastCampaignAdd from "./BroadcastCampaignAdd";
import BroadcastMessagesModal from "../Leads/components/BroadcastMessages";
import ChartsModal from "./ChartsModal";
import Templates from "../Templates/Templates";

const BroadcastCampaign = () => {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);
  const [modalSend, setModalSend] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalData, setModalData] = useState({});
  const [broadcastCampaignId, setBroadcastCampaignId] = useState(null);
  const [modalCharts, setModalCharts] = useState(false);
  const [managementData, setManagementData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(null)
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState("")
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const openChartModal = (data) => {
    setManagementData({
      exitosos: data.exitosos,
      fallidos: data.fallidos,
      sin_telefono: data.sin_telefono,
      multiples_telefonos: data.multiples_telefonos,
      total_contactos: data.total_contactos,
    });
    setModalCharts(true);
  };
  const getData = async () => {
    const response = await getBroadcastCampaign();
    setLoading(true);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    getFilterData(`?search=${search}`);
  };

  const resetSearch = () => {
    getFilterData(`?search=`);
    setSearch("");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getBroadcastCampaignFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const postExcel = async () => {
    setShowToast(true); // Mostrar la notificación al empezar
    setSending(true); // Marcar que está cargando
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await postBroadcastConfirmation(formData);
      console.log(response);
      setStatus(response.status);
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setSending(false); // Finaliza la carga
    }
    setAlert(true);
  };

  const openModal = (data) => {
    setModalData(data);
    setModalDetails(true);
  };

  // Handler para enviar la consulta con filtros de fechas
  const handleSearch = () => {
    const filters = `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;
    getFilterData(filters);
  };
  const filteredData = Array.isArray(modalData)?  modalData.filter(contacto =>
    contacto.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contacto.telefono.includes(searchTerm)
  ):[];
  return (
    <React.Fragment>
      <div style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1050 }}>
        <Toast
          isOpen={showToast}
          style={{
            backgroundColor: sending ? '#f9c74f' : '#90be6d', // Amarillo para "cargando" y verde para "cargado"
            borderRadius: '10px', // Bordes redondeados
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Sombra para dar un efecto de elevación
          }}
        >
          <ToastBody
            style={{ color: '#fff', fontSize: '18px' }} // Texto blanco en el cuerpo
          >
            {sending
              ? <b>El archivo está en proceso de carga, por favor espera...</b>
              : <b>El archivo se ha cargado correctamente.</b>}
          </ToastBody>
        </Toast>
      </div>
      {status === 200 && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
            setShowToast(false)
            setSelectedFile("")
            getData()
          }}
        >{data.message}
        </SweetAlert>
      ) : null}
      {status !== 200 && alert ? (
        <SweetAlert
          title="Error"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
          }}
        > {data.error}
        </SweetAlert>
      ) : null}
      <div>
        {modalTemplateOpen ? <Templates isOpen={modalTemplateOpen} onClose={() => setModalTemplateOpen(false)} /> : null}
        {modalAdd ? <BroadcastCampaignAdd isOpen={modalAdd} onClose={() => setModalAdd(false)} refresh={() => getData()} /> : null}
        {modalSend ? <BroadcastMessagesModal isOpen={modalSend} onClose={() => setModalSend(false)} broadcastCampaign={broadcastCampaignId} refresh={() => getData()} /> : null}
        {modalCharts ? <ChartsModal isOpen={modalCharts} toggle={() => setModalCharts(false)} managementData={managementData} /> : null}
        <Container fluid>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Row className="mb-2 mt-3">
                <Col md={7}>
                  <input
                    type="file"
                    id="excelFile"
                    accept=".xls, .xlsx"
                    style={{ display: 'none' }}
                    onChange={(event) => setSelectedFile(event.target.files[0])}
                  />
                  <Button
                    color="secondary"
                    className="me-1 mt-2 btn-sm text-dark"
                    onClick={() => {
                      document.getElementById('excelFile').click();
                    }}
                  >
                    <span>
                      <img className="me-1" alt="" src={excelLogo} height="20px" />
                    </span>
                    <b>Subir Confirmaciones</b>
                  </Button>

                  <Link
                    className="btn btn-success waves-effect waves-light btn-sm mb-0 mt-2"
                    to="#"
                    onClick={() => setModalAdd(true)}
                  >
                    <b>Agregar Campañas de Difusión</b>  {" "}
                  </Link>
                  <Link
                    className="btn btn-info waves-effect waves-light btn-sm mb-0 mt-2 ms-1"
                    to="#"
                    onClick={() => setModalTemplateOpen(true)}
                  >
                    <b>Crear Templates</b> {" "}
                  </Link>
                  {selectedFile && (
                    <p className="font-size-10 ms-3 mb-1">
                      Archivo seleccionado: {selectedFile.name}
                    </p>
                  )}
                  {selectedFile && (
                    <>
                      <Button
                        color="success"
                        className="mb-2 ms-2 btn-sm"
                        onClick={() => { postExcel() }}
                      >
                        Generar
                      </Button>
                      <Button
                        color="danger"
                        className="mb-2 ms-2 btn-sm"
                        onClick={() => {
                          setSelectedFile(null);
                        }}
                      >
                        Borrar
                      </Button>
                    </>
                  )}
                </Col>
                <Col md={5}>
                  <Form className="search-box" onSubmit={onSubmit}>
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Nombre..."
                        value={search}
                        onChange={onChange}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <Button color="primary" onClick={resetSearch}>
                        <span className="bx bx-x fa-lg align-middle"></span>
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
              <Card>
                {params.results && params.results.length > 0 ? (
                  <CardBody className="ps-5 pe-5">
                    <Row className="mb-3">
                      <Col md={4}>
                        <h2 className="font-size-20"> Campañas de Difusión</h2>
                      </Col>
                      <Col md={3}>
                        <Input
                          type="date"
                          value={fechaInicio}
                          onChange={(e) => setFechaInicio(e.target.value)}
                          placeholder="Fecha inicio"
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          type="date"
                          value={fechaFin}
                          onChange={(e) => setFechaFin(e.target.value)}
                          placeholder="Fecha fin"
                        />
                      </Col>
                      <Col md={2}>
                        <Button color="primary" onClick={handleSearch}>
                          Buscar
                        </Button>
                        <Button className='ms-1' color="danger" onClick={resetSearch}>
                          Borrar
                        </Button>
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <Table
                        className="table align-middle table-centered mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark align-middle">
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Canal</th>
                            <th>Campaña</th>
                            <th>Estado</th>
                            <th>Descripción</th>
                            <th>Template</th>
                            <th>Exitosos</th>
                            <th>Fallidos</th>
                            <th>Múltiple Teléfono</th>
                            <th>Descripción de la Gestión</th>
                            <th>Estadísticas</th>
                            <th>Enviar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((item, index) => (
                            <tr key={index}>
                              <td><b>{index + 1}</b></td>
                              <td>{item.name}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].channel : null}</td>
                              <td>{item.campaign}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].status : null}</td>
                              <td>{item.descripcion}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].template : null}</td>
                              <td>
                                <Link to="#" onClick={() => openModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata.exitosos_detalles.detalles : null)}>
                                  {item.managements && item.managements.length > 0 ? item.managements[0].metadata.exitosos : null}
                                </Link>
                              </td>
                              <td>
                                <Link to="#" onClick={() => openModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata.fallidos_detalles.detalles : null)}>
                                  {item.managements && item.managements.length > 0 ? item.managements[0].metadata.fallidos : null}
                                </Link>
                              </td>
                              <td>
                                <Link to="#" onClick={() => openModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata.multiples_telefonos_detalles.detalles : null)}>
                                  {item.managements && item.managements.length > 0 ? item.managements[0].metadata.multiples_telefonos : null}
                                </Link>
                              </td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].description : null}</td>
                              <td>
                                {item.managements && item.managements.length > 0 ? (
                                  <Link to="#" onClick={() => openChartModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata : null)}>
                                    <span
                                      className="fas fa-chart-bar fa-md"
                                      style={{ cursor: "pointer" }}
                                    ></span>
                                  </Link>
                                ) : null}
                              </td>
                              <td>
                                {item.managements && item.managements.length === 0 ? (
                                  <Link to="#" onClick={() => { setModalSend(true); setBroadcastCampaignId(item.id) }}>
                                    <span
                                      className="fas fa-paper-plane fa-md"
                                      style={{ cursor: "pointer" }}
                                    ></span>
                                  </Link>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>


                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl={`whatsapp/template/`}
                      filterFunction={getFilterData}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
              <Modal isOpen={modalDetails} toggle={() => setModalDetails(false)} size="lg">
                <h5 className="text-primary pt-3 px-3">Detalles</h5>
                <ModalBody style={{ overflowY: 'scroll', maxHeight: "350px" }}>
                  {/* Campo de entrada para buscar */}
                  <Input
                    type="text"
                    placeholder="Buscar por contacto o número..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="mb-3"
                  />
                  {filteredData && filteredData.length > 0 ? (
                    <Table className="table table-sm align-middle mb-3 text-center" responsive hover>
                      <thead className="table-dark">
                        <tr>
                          <th>Contacto</th>
                          <th>Teléfono Enviado</th>
                          <th>Mensajes Exitosos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((contacto, index) => (
                          <tr key={index}>
                            <td>{contacto.nombre}</td>
                            <td>{contacto.telefono}</td>
                            <td>{contacto.mensajes_exitosos}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No hay detalles disponibles</p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" className="text-dark" onClick={() => setModalDetails(false)}>Cerrar</Button>
                </ModalFooter>
              </Modal>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BroadcastCampaign;
