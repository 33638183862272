import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import { getAgent, getCampaignFullList, getGestionesContactoRead, getReportsBot } from "../../helpers/backend";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";
import { ResponsiveContainer } from 'recharts';
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ReportsBot = () => {
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState('');
  const [type, setType] = useState('');
  const [agente, setAgente] = useState('');
  const [campaña, setCampaña] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [chartData, setChartData] = useState({ series: [], options: {} });
  const [showGraphic, setShowGraphic] = useState(false)

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const handleSelectTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions || []);
    setType(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };

  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  }
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };

  const getReportData = async () => {
    setLoading(true)
    const response = await getReportsBot(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña}&type=${type}&is_leads=${isLead}`);
    if (fechaInicio || fechaFin) {
      const data = response.data; // Supongamos que la respuesta es el objeto de datos esperado  
      transformDataForChart(data);
      setLoading(false);
      setData(response.data);
      setLoading(false);
      setShowWarning(false)
      setShowGraphic(true)
    }
    else {
      setShowWarning(true);
    }
  };

  const resetSearch = () => {
    setFechaFin("");
    setFechaInicio("");
    setEstado('');
    setAgente('');
    setSelectedAgents("");
    setSelectedCampaign("");
    setSelectedEstado("");
    setSelectedType("")
    setType("");
    getReportData();
    setShowGraphic(false)
    setShowWarning(false)
  };
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setFechaInicio(today);
    setFechaFin(today);

    // Llamar a `getReportData` solo si ambas fechas están configuradas
    if (fechaInicio && fechaFin) {
      getReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin]);
  useEffect(() => {
    getAgentData();
    getCampaignData();
  }, []);

  const transformDataForChart = (data) => {
    const { gestiones_en_bot, gestiones_fuera_bot } = data;
    const series = [gestiones_en_bot, gestiones_fuera_bot];
    const options = {
      labels: ['Conversaciones en Bot', 'Conversaciones fuera del Bot'],
      colors: ['#0088FE', '#FF8042'],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '18',
      },
    };
    setChartData({ series, options });
  };
  const handleGestionDetallada = async (id) => {
    const response = await getGestionesContactoRead(id)
    setGestionDetallada(response.data)
    setDetallesModal(true)
  }
  console.log(data);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Reporte Ingresados al Bot </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Reporte Ingresados al Bot " />

          {detallesModal ? (
            <ModalManagementDetails
              isOpen={detallesModal}
              onCloseClick={() => setDetallesModal(false)}
              GestionDetallada={GestionDetallada}
            ></ModalManagementDetails>
          ) : null}

          {loading ? (
            <Loader />
          ) : (
            <div>
              {detallesModal ? (
                <ModalManagementDetails
                  isOpen={detallesModal}
                  onCloseClick={() => setDetallesModal(false)}
                  GestionDetallada={GestionDetallada}
                ></ModalManagementDetails>
              ) : null}
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaInicio"
                            placeholder="Fecha Inicio"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaFin"
                            placeholder="Fecha Fin"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Select
                            onChange={handleSelectEstadoChange}
                            options={[
                              { value: 'EN_GESTION', label: 'En Gestión' },
                              { value: 'VENCIDO', label: 'Vencido' },
                              { value: 'FINALIZADO', label: 'Finalizado' },
                              { value: 'PENDIENTE', label: 'Pendiente' }
                            ]}
                            placeholder="Estado"
                            value={selectedEstado}
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={Array.isArray(agent)
                              ? agent.map((item) => ({
                                label: item.get_full_name,
                                value: item.id,
                              }))
                              : []}
                            value={selectedAgents}
                            onChange={handleSelectChange}
                            isMulti={true}
                            placeholder="Agentes"
                            menuPosition="fixed"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={Array.isArray(campaignData)
                              ? campaignData.map((item) => ({
                                label: item.name,
                                value: item.id,
                              }))
                              : []}
                            onChange={handleSelectCampaignChange}
                            value={selectedCampaign}
                            menuPosition="fixed"
                            placeholder="Campañas"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={[
                              { value: 'WhatsApp', label: 'WhatsApp' },
                              { value: 'Telefonía - Manual', label: 'Telefonía - Manual' }
                            ]}
                            onChange={handleSelectTypeChange}
                            value={selectedType}
                            menuPosition="fixed"
                            placeholder="Tipo"
                          />
                        </Col>
                        <Col>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="isLeadCheck"
                              checked={isLead}
                              onChange={(e) => setIsLead(e.target.checked)}
                            />
                            <Label className="form-check-label" for="isLeadCheck">
                              Lead?
                            </Label>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <Button color="warning" className="btn-sm me-1 mt-1" onClick={getReportData}>
                            <b>Consultar</b>
                          </Button>
                          <Button color="danger" className="btn-sm me-1 mt-1" onClick={resetSearch}>
                            <b>Borrar</b>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    {showWarning && (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        Por favor, coloque un rango de fechas. La fecha es requerida.
                      </div>
                    )}
                  </Row>
                </CardBody>

              </Card>
              {showGraphic ?
                <>
                  <Row>
                    <Col lg={12}>
                      <Card >
                        <div className="d-flex justify-content-center mt-3">
                          <ResponsiveContainer width="100%" height={400}>
                            <ReactApexChart
                              options={chartData.options}
                              series={chartData.series}
                              type="pie"
                              className="apex-charts "
                              dir="ltr"
                            />
                          </ResponsiveContainer>

                        </div>

                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="custom-scroll " style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <Table className="table table-sm text-center mb-3 font-size-15" hover responsive>
                          <thead className="table-dark align-middle">
                          <h6 className="text-dark">Conversaciones fuera del bot</h6>
                            <tr>
                              <th>Contacto</th>
                              <th>Campaña</th>
                              <th>Estado</th>
                              <th>Fecha</th>
                              <th>Canal de Comunicación</th>
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.gestiones_fuera_bot_list && data.gestiones_fuera_bot_list.map((gestion) => (
                              <tr key={gestion.id}>
                                <td>{gestion.contacto}</td>
                                <td>{gestion.campana ? gestion.campana.map(item => item[0]) : null}</td>
                                <td>{gestion.estado}</td>
                                <td>{gestion.created_date}</td>
                                <td>{gestion.type}</td>
                                <td><Link to="#" onClick={() => handleGestionDetallada(gestion.id)}><span className="fas fa-eye fa-lg"></span></Link></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                    </Col>
                    <Col lg={6}>
                      <div className="custom-scroll " style={{ maxHeight: "400px", overflowY: "auto" }}>
                        <Table className="table table-sm text-center mb-3 font-size-15" hover responsive>
                          <thead className="table-dark align-middle">
                            <h6 className="text-dark">Conversaciones en bot</h6>
                            <tr>
                              <th>Contacto</th>
                              <th>Campaña</th>
                              <th>Estado</th>
                              <th>Fecha</th>
                              <th>Canal de Comunicación</th>
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.gestiones && data.gestiones.map((gestion) => (
                              <tr key={gestion.id}>
                                <td>{gestion.contacto}</td>
                                <td>{gestion.campana ? gestion.campana.map(item => item[0]) : null}</td>
                                <td>{gestion.estado}</td>
                                <td>{gestion.created_date}</td>
                                <td>{gestion.type}</td>
                                <td><Link to="#" onClick={() => handleGestionDetallada(gestion.id)}><span className="fas fa-eye fa-lg"></span></Link></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                    </Col>
                  </Row>
                </>
                : null}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}


export default ReportsBot;
