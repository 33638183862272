import React, { useEffect, useCallback } from "react";

const PaginationTest = (props) => {
  let next = props.next;
  
  const paginationHandler = useCallback(() => {
      //  console.log(next)
    if(next){

      const parameters = new URL(next);
      //    console.log(parameters)
      const offset = parseInt(parameters.searchParams.get("offset"), 10);
    
      // console.log(changeUrl);
     console.log(props.selectedGroup)

      if(props.selectedGroup!==""  && props.selectedGroup!==undefined){
        const changeUrl = `${props.selectedGroup}/contactos/?offset=${offset}`;
        props.filterFunction({ changeUrl },props.selectedGroup );
    }
    else{
      const changeUrl = `?offset=${offset}`;
      props.filterFunction({ changeUrl });    

    }  
    }
  }, [next, props]);
  
  useEffect(() => {
    paginationHandler();
  }, [paginationHandler]);
  return <></>;
};
    
    export default PaginationTest;
