import React, { useEffect, useState, useRef, useCallback } from 'react';

import './ContactDirectory.css';
import {  getGrupoContactoFilterPaginado, getContactsList , getContactsFilter, getGrupoContactoFilter} from '../helpers/backend';
import { Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { faWhatsapp,  } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PaginationTest from './PaginationTest';

const ContactDirectory = () => {
  const history = useHistory()
  const [dropdownOpenChat, setDropdownOpenChat] = useState(false);
  const [activeIndexChat, setActiveIndexChat] = useState(null);
  const [contactId, setContactId] = useState(null)
  const toggleDropdownChat = (idx) => {
    setDropdownOpenChat(!dropdownOpenChat);
    setActiveIndexChat(idx);
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(''); // id del grupo seleccionado
  const [contacts, setContacts] = useState([]);
  const [contactGroup, setContactGroup] = useState([]);
  const [next, setNext] = useState([]);
  const [triggerPagination, setTriggerPagination] = useState(false);	
  // console.log(next)
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useState(false);
// console.log(selectedGroup)
 
const handleItemClick = (selectedChatId, contactId, type, template) => {
  history.push({
    pathname: '/chat',
    state: { selectedChatId, contactId, type, template }
  });
  console.log(contactId)
}
const getContactData = async () => {
    const response = await getContactsList();
    
    setContacts(response.data.results);
    setNext(response.data.next);
  };
  const getContactDataFIltro = useCallback(async (params, selectedGroup) => {
    try {
      const response = await getGrupoContactoFilterPaginado(params);
      console.log("Respuesta de filterFunction:", response);
  
      if (response && response.data) {
        const newContacts = response.data.results;
  
        // Actualiza contactos generales y paginación
        setContacts(newContacts);
        setNext(response.data.next);
        setTriggerPagination(false);
  
        // Si es el mismo grupo seleccionado, agrega solo los contactos nuevos
        setContactsFull((prevContacts) => [
          ...prevContacts, // Mantén los contactos actuales
          ...newContacts.filter(
            (contact) => !prevContacts.some((c) => c.id === contact.id) // Evita duplicados
          ),
        ]);
      }
    } catch (error) {
      console.error("Error en filterFunction:", error);
    }
  }, []);

  useEffect(() => {
    if (selectedGroup ==="") {
 
      getContactData();
    }else{
      const changeUrl = `${selectedGroup}/contactos/`;
      getContactDataFIltro({ changeUrl });
    }
    
     getContactGroup();
   
   }, [selectedGroup, getContactDataFIltro]);

// console.log(next)
  const handleFilter = async (params) => {
    try {
      const response = await getContactsFilter(params);
      console.log("Respuesta de filterFunction:", response);
  
      // Opcional: Actualizar contactos si es necesario
      if (response && response.data) {
        setContacts(response.data.results);
        setNext(response.data.next);
        setTriggerPagination(false); 
      }
    } catch (error) {
      console.error("Error en filterFunction:", error);
    }
  };
  

  // Obtener lista de grupos de contacto
  const getContactGroup = async () => {
    try {
      const response = await getGrupoContactoFilter("FullList/");
      setContactGroup(response.data);
    } catch (error) {
      console.error('Error fetching contact group data', error);
    }
  };

 

  const onSearch = (e) => {
    setInputSearch(true)
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    // Ejecuta la función de filtro cada vez que se actualiza el campo de búsqueda.
    handleFilter({ changeUrl: `?search=${searchTerm}` });
  };
  const listRef = useRef(null);


 const [contactsFull, setContactsFull] = useState([]); //ContactaosFull 
// console.log(selectedGroup)
// console.log(contactsFull)

 useEffect(() => {


  if (Array.isArray(contacts) && inputSearch ===false &&  selectedGroup===""  ) {
    console.log("Agarro 1")
      setContactsFull((prevContacts) => [
        ...prevContacts, // Mantén los contactos actuales
        ...contacts.filter(
          (contact) => !prevContacts.some((c) => c.id === contact.id)
        ), // Solo agrega nuevos contactos que no estén ya en el estado full
      ]);
    

  }

   if (inputSearch) {
    console.log("Agarro 2")
    setContactsFull(contacts)
   }

   
   

}, [contacts, inputSearch, selectedGroup, searchTerm]);


  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      // Verifica si estamos cerca del final del contenedor
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setTriggerPagination(true); 
        // console.log("llegue al final")
      }
    }
  };

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

console.log(contactsFull)
  // Filtramos los contactos basados en el grupo seleccionado y el término de búsqueda
  

  return (
    <div className="contact-directory">

{triggerPagination && 

<PaginationTest next={next}
  filterFunction={selectedGroup ==="" ?  handleFilter : getContactDataFIltro}
  setSearchTerm={setSearchTerm}	
  selectedGroup={selectedGroup}
  contactId={contactId}

/>}
      <h2 className="title">Directorio de Contactos</h2>
      
      {/* Select de grupos */}
      <Input
        type="select"
        value={selectedGroup}
        onChange={(e) => {
          setSelectedGroup(e.target.value)
          setContactsFull([])

        }} // id del grupo seleccionado
        className="group-select"
      >
        <option value="">Todos los grupos</option>
        {Array.isArray(contactGroup) && contactGroup.map((item) => (
          <option key={item.id} value={item.id}> {/* El value es el id del grupo */}
            {item.name} {/* Muestras el nombre del grupo */}
          </option>
        ))}
      </Input>

      {/* Campo de búsqueda */}
      <Input
        type="text"
        className="mt-1 mb-1"
        value={search}
        onChange={(e) => {
          onSearch(e);  // Llamamos a la función onSearch para actualizar el valor del input
          if (e.target.value === "") {
            setInputSearch(false);  // Si el valor está vacío, seteamos InputSearch a false
          }
        }}// Llamar a onSearch cada vez que se cambia el valor
        placeholder="Buscar contacto..."
      />

      {/* Lista de contactos */}
      <div className="contact-list" ref={listRef} style={{ overflowY: "auto", maxHeight: "80vh" }}>
      {contactsFull &&
        contactsFull.map((contact, index) => (
          <div key={index} className="contact-card">
            <div
              className="contact-initials"
              style={{ backgroundColor: getRandomColor() }}
            >
              {contact.get_full_name.slice(0, 2).toUpperCase()}
            </div>
            <div className="contact-info">
              <div className="contact-details">
                <span className="contact-name-directory">
                  {contact.get_full_name}
                </span>
                <span className="contact-phone">{contact.telefono}</span>
              </div>
              <Dropdown
                                isOpen={dropdownOpenChat && activeIndexChat === index} toggle={() => toggleDropdownChat(index)}>
              <DropdownToggle
                                  tag="span"
                                  onClick={() => { toggleDropdownChat(); setContactId(contacts.id) }}
                                >
              {/* <FaWhatsapp
                className="whatsapp-icon"
                onClick={() => {
                  window.focus();
                  history.replace({
                    pathname: "/chat",
                    state: {
                      selectedChatId: contact.telefono,
                      contactId: contact.id,
                      type: "WhatsApp",
                    },
                  });
                }}
              /> */}
               <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25d366' }} className='social-icon' />
                </DropdownToggle>
                <DropdownMenu 
                className='dropdown-menu-contactDirecotry'
                left>
                                  {Array.isArray(contact.phone) && contact.phone.length > 0 ? (
                                    contact.phone.map((item, idx) => (
                                      <DropdownItem key={idx} onClick={() =>
                                        handleItemClick(item.telefono, contact.id, 'WhatsApp', true)
                                      }>{item.telefono}</DropdownItem>
                                    ))
                                  ) : (
                                    <DropdownItem>No tiene número registrado</DropdownItem>
                                  )}
                                </DropdownMenu>
                </Dropdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Función para generar colores aleatorios
const getRandomColor = () => {
  const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff4d4f', '#87d068'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default ContactDirectory;
