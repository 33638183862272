import React, { useState, useEffect } from 'react';
import './PhonePanel.css';
import { FaPhone, FaPhoneSlash,  FaPhoneAlt, FaArrowRight } from 'react-icons/fa';
import { getCallStart } from '../helpers/backend';
import ManagementManual from '../pages/Whatsapp/components/ManagementManual';

const PhonePanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [callTime, setCallTime] = useState(0);
  const [isCalling, setIsCalling] = useState(false);
  const [dialedNumber, setDialedNumber] = useState('');
  const [isRegistered, setIsRegistered] = useState(true); // Simulación de estado de registro
  const [manualOpen, setManualOpen] = useState(false);

  useEffect(() => {
    let timer;
    if (isCalling) {
      timer = setInterval(() => setCallTime(prevTime => prevTime + 1), 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isCalling]);
  const handleDial = (num) => setDialedNumber(dialedNumber + num);
  const handleInputChange = (e) => setDialedNumber(e.target.value);
  const togglePanel = () => setIsOpen(!isOpen);

  const startCall = async () => {
    setIsCalling(true);
    setCallTime(0);
    setManualOpen(true)
    await getCallStart({
      number: `57${dialedNumber}`,
      extension: '1203'
    })
  };
  const endCall = () => {
    setIsCalling(false);
    setDialedNumber('');
  };


  return (
    <div>
      {manualOpen &&
        <ManagementManual isLead={true} isOpen={manualOpen} onClose={() => setManualOpen(false)} />}
      <button className="floating-button-PhonePanel" onClick={togglePanel}>
        <FaPhoneAlt />
      </button>
      {isOpen && (
        <div className="phone-panel">
          <div className="registration-status">
            <span className={isRegistered ? 'registered' : 'not-registered'}>
              {isRegistered ? 'Registrado' : 'No Registrado'}
            </span>
          </div>
          <input
            type="text"
            className="dial-display"
            value={dialedNumber}
            onChange={handleInputChange}
            placeholder="Ingrese número"
          />
          <div className="dial-pad">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((num) => (
              <button key={num} className="dial-button" onClick={() => handleDial(num)}>
                {num}
              </button>
            ))}
          </div>
          <div className="call-actions">
            {!isCalling && (
              <button className="call-button" onClick={startCall} title="Llamar">
                <FaPhone />
              </button>
            )}
            {isCalling && (
              <button className="end-call-button" onClick={endCall} title="Colgar">
                <FaPhoneSlash />
              </button>
            )}
            <button className="transfer-button" title="Transferir">
              <FaArrowRight />
            </button>
          </div>
          {isCalling && <div className="call-timer">Tiempo de llamada: {callTime}s</div>}
          {isCalling && <div className="call-indicator">En llamada...</div>}
        </div>
      )}
    </div>
  );
};

export default PhonePanel;
