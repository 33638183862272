import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import { getCampaignFullList, postTemplate } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";
import EmojiPicker from "emoji-picker-react";
import "./ModalTemplateAdd.css";

const ModalTemplateAdd = ({ isOpen, onClose, refresh }) => {
  const [formData, setFormData] = useState({
    name: "",
    body: "",
    campaign: [],
    languaje_code: "es",
    type_template: "",
    file: null,
    fileName: null,
    filePreview: null,
  });

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getCampaignData = async () => {
      try {
        const response = await getCampaignFullList();
        if (isMounted) {
          setCampaignData(response.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(true);
        }
      }
    };

    getCampaignData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let fileType = "document";
        if (file.type.startsWith("image/")) fileType = "image";
        else if (file.type.startsWith("video/")) fileType = "video";

        setFormData({
          ...formData,
          file: reader.result,
          filePreview: reader.result,
          fileName: file.name,
          type_template: fileType,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setFormData({
      ...formData,
      body: formData.body + emojiObject.emoji,
    });
    setShowEmojiPicker(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postTemplate(formData);
      console.log(response)
      if (response.status === 201) {
        setAlert(true);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  const formatPreviewText = (text) => {
    const boldRegex = /\*([^*]+)\*/g; // Detecta texto entre *
    const italicRegex = /_([^_]+)_/g; // Detecta texto entre _
    return text
      .replace(boldRegex, "<strong>$1</strong>")
      .replace(italicRegex, "<em>$1</em>");
  };

  return (
    <Modal size="xl" isOpen={isOpen} toggle={onClose}>
      {alert && (
        <SweetAlert
          title="Creado"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            refresh();
            onClose();
            setAlert(false);
          }}
        />
      )}
      {error && (
        <SweetAlert
          title="Error"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setError(false);
          }}
        />
      )}
      <div className="p-3">
        <h4>Agregar Templates</h4>
        <Row>
          <Col md={6}>
            <Form onSubmit={handleSubmit}>
              {/* Formulario */}
              <FormGroup className="mt-3">
                <Label for="name">Nombre</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Nombre del template"
                />
              </FormGroup>
              <FormGroup>
                <Label for="campaign">Campaña</Label>
                <Select
                  options={campaignData.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedCampaigns = selectedOptions.map(
                      (option) => option.value
                    );
                    setFormData({
                      ...formData,
                      campaign: selectedCampaigns,
                    });
                  }}
                  placeholder={"Seleccione una o más campañas"}
                  isMulti={true}
                  menuPosition="fixed"
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="body">Texto</Label>
                <div className="position-relative">
                  <Input
                    type="textarea"
                    name="body"
                    id="body"
                    value={formData.body}
                    onChange={handleInputChange}
                    placeholder="Escribe el contenido del template"
                    rows={3}
                    style={{height: '200px'}}
                  />
                  <span
                    className="position-absolute"
                    style={{ bottom: 10, right: 10, cursor: "pointer" }}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    😊
                  </span>
                  {showEmojiPicker && (
                    <div
                      className="position-absolute"
                      style={{ bottom: 50, right: 10 }}
                    >
                      <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>
              </FormGroup>
              <FormGroup className="mt-3">
                  <Label for="body" >Archivo</Label>
                  <Input
                  className="form-control"
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                  />
              </FormGroup>
            </Form>
          </Col>
          {/* Vista previa */}
          <Col md={6}>
            <Label className="text-muted">Vista previa del mensaje</Label>
            {/* Vista previa del mensaje */}
            <div
              className="message-container-preview p-3"
              style={{
                height: "90%",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <div className="received-message-preview">
                {/* Mostrar vista previa del archivo si existe */}
                {formData.type_template === "image" && formData.filePreview && (
                  <img
                    src={formData.filePreview}
                    alt="Vista previa"
                    className="image-preview"
                  />
                )}
                {formData.type_template === "video" && formData.filePreview && (
                  <video
                    src={formData.filePreview}
                    controls
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                )}
                {formData.type_template === "document" && formData.fileName && (
                  <div className="file-preview">
                    <strong>📄 {formData.fileName}</strong>
                  </div>
                )}
                {/* Siempre mostrar el texto del textarea */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatPreviewText(formData.body),
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* Botones */}
        <Row>
          <Col>
            <div className="d-flex justify-content-end mt-5">
              <Button
                color="secondary"
                onClick={onClose}
                className="text-dark me-1"
              >
                Cancelar
              </Button>
              <Button color="primary" onClick={handleSubmit} className="ml-2">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalTemplateAdd;
