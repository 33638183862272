import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
 } from "reactstrap"
 import { IoEllipsisVertical } from 'react-icons/io5';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination"
import styles from "../Contacts/Contacts.module.scss"
import { surveyList, surveyFilter } from "../../store/survey/actions"
import SurveyFull from "../Modales/SurveyFull";


const Survey = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [surveyId, setSurveyId] = useState(null);

  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);
    setActiveIndex(idx);
  };
  const [search, setSearch] = useState("");

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(surveyFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(surveyFilter(`?search=`));
    setSearch("");
  };


  useEffect(() => {
    dispatch(surveyList())
  }, [dispatch])

  const params = {
    count: props.survey.count,
    next: props.survey.next,
    previous: props.survey.previous,
    results: props.survey.results,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Encuestas </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Encuestas" />

          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/survey/create"
                      >
                        Agregar
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Documento, nombre..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>                 
              <div className={`text-center ${styles['card-container']}`}>
          {params.results &&
            params.results.map((survey, idx) => (
              <Card key={idx} className={`${styles.card} position-relative`} onClick={() => {setModalOpen(true); setSurveyId(survey.id)}}>
                <div className="position-absolute top-0 end-0">
                  <Dropdown isOpen={dropdownOpen && activeIndex === idx} toggle={() => toggleDropdown(idx)}>
                    <DropdownToggle
                      tag="span"
                      onClick={() => toggleDropdown(idx)}
                      className={`${styles.menu}`}
                    >
                      <IoEllipsisVertical size={15} />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => history.push(`/survey/update/${survey.id}`)}>Editar</DropdownItem>
                      {/* Otras opciones */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="text-center">
                  <h4 className="text-primary"><b>{survey.name}</b></h4>
                </div>
                <div className={`${styles['card-title']}`}>
                  <b>{survey.survey_medium}</b> <br />
                  <p className="font-size-12">
                  {survey.description}
                  </p>
                </div>
              </Card>
            ))}
        </div>
            </div>

          )}
        {modalOpen ? 
          <SurveyFull 
            isOpen = {modalOpen}
            onClose = {()=> setModalOpen(false)}
            surveyId = {surveyId}
          /> 
        : null}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={30}
            baseUrl="produccion/survey/"
            filterFunction={surveyFilter}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  const { error, loading, survey } = state.Survey;
  return { error, loading, survey };
};

export default withRouter(connect(mapStateToProps)(Survey));
