import React, { useEffect, useState } from 'react';
import { Button, Col,  Label, Modal,  Row,  FormGroup } from 'reactstrap';

import Select from "react-select";

import 'react-phone-input-2/lib/style.css'
import { getContactsRead, getGrupoContactoFilter, postGrupoContactoAdd, postGrupoContactoRemove } from '../../../helpers/backend';

// Redux
const GroupAssign = ({ isOpen, onClose, contactId }) => {
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [contactData, setContactData] = useState({})  
  const [groupsOfContact, setGroupsOfContact] = useState([])
  const [selectedContactIds, setSelectedContactIds] = useState([]);
 

  const getContactData = async () => {
    const response = await getContactsRead(contactId)
    setContactData(response.data)
  }
  const [groupsData, setGroupsData] = useState({})
  const getGroupsData = async () => {
    const response = await getGrupoContactoFilter("FullList")
    console.log(response);
    setGroupsData(response.data)
  }

 useEffect(() => {
      getContactData()
      getGroupsData()
      // dispatch(contactsList());
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 useEffect(() => {
  if (contactData && contactData.grupos !== groupsOfContact) {
    setGroupsOfContact(contactData.grupos);
  }
}, [contactData, groupsOfContact]);

// const state = useSelector((state) => state);
// console.log(state)

 const handleCheckboxChange = (id) => {
  setSelectedContactIds((prevSelectedIds) => {
    if (prevSelectedIds.includes(id)) {
      return prevSelectedIds.filter((selectedId) => selectedId !== id);
    } else {
      return [...prevSelectedIds, id];
    }
  });
};
const handleContactClick = (id) => {
  handleCheckboxChange(id);
};
const handleRemoveContacts = async () => {
  const response = await postGrupoContactoRemove(contactId, {
    grupo: selectedContactIds,
  })
  console.log(response);
  if (response.status === 200) {
    getContactData()
    setSelectedGroup(null)
  }
};
const assignContacts = async () => {
  const groupIds = selectedGroup.map(group => group.value); // Extrae solo los ID
  const response = await postGrupoContactoAdd(contactId, {
    grupo: groupIds
  });
  console.log(response);
  if (response.status === 200) {
    getContactData();
    setSelectedGroup(null);  // Limpia la selección del Select
  }
};

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
      size="lg"
    >
      <h4 className="text-primary m-3">{contactData.get_full_name}</h4>
      <h6 className="p-2 text-dark">Agrega grupos al contacto</h6>
      <div className="px-3">
        <Row className="d-flex justify-content-center">
          <Row className="mb-2">
            <Col lg={12} className="mt-1">
            <Select
              style={{ minHeight: "100px" }}
              options={Array.isArray(groupsData)
                ? groupsData
                    .filter(group => !groupsOfContact.some(([id]) => id === group.id)) // Filtra los grupos que ya están asignados
                    .map((item) => ({
                      label: item.name, // Etiqueta que se mostrará en el Select
                      value: item.id,   // Valor único del grupo
                    }))
                : []
              }
              onChange={(selectedOptions) => {
                setSelectedGroup(selectedOptions); // Guarda el objeto completo (label y value)
              }}
              value={selectedGroup} // Pasamos el objeto seleccionado completo (label y value)
              placeholder={"Seleccione"}
              isMulti={true}
              menuPosition="fixed"
            />

            </Col>
          </Row>
          {selectedGroup ?
            <Row className="mt-3 p-2">
              <Col className="d-flex justify-content-center">
                <Button
                  color="success"
                  className="btn btn-sm"
                  onClick={assignContacts}
                >
                  Agregar
                </Button>
              </Col>
            </Row>
            : null}
        </Row>
        <Row className="mt-3">
          <Col>
            <FormGroup>
              <Label>Grupos:</Label>
              <div
                className="d-flex flex-wrap"
                style={{
                  maxHeight: '300px', // Ajusta según sea necesario
                  overflowY: 'auto',
                }}
              >
                {groupsOfContact && groupsOfContact.map(([id, name]) => (
                  <div key={id} className="p-2" style={{ flexBasis: '25%' }}> {/* Ajusta el porcentaje según el número de columnas deseadas */}
                    <input
                      type="checkbox"
                      checked={selectedContactIds.includes(id)}
                      onChange={() => handleCheckboxChange(id)}
                      style={{ cursor: 'pointer' }}
                    />
                    <span
                      className="ms-2"
                      onClick={() => handleContactClick(id)}
                      style={{ cursor: 'pointer', textDecoration: selectedContactIds.includes(id) ? 'underline' : 'none' }}
                    >
                      {name}
                    </span>
                  </div>
                ))}
              </div>
            </FormGroup>

          </Col>
        </Row>
      </div>

      {selectedContactIds.length > 0 && (
        <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-center">
            <Button
              color="danger"
              className="btn btn-sm"
              onClick={handleRemoveContacts}
            >
              Remover
            </Button>
          </Col>
        </Row>
      )}

      <Row className="mt-3 p-2">
        <Col className="d-flex justify-content-end">
          <Button
            className="text-dark btn btn-secondary"
            onClick={onClose}
          >
            Cerrar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default GroupAssign;
