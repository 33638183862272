import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getGestionesContactoRead, getReportsBot } from "../../../helpers/backend";
import { Card, Modal, ModalBody, Table } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ResponsiveContainer } from "recharts";
import ModalManagementDetails from "../../Whatsapp/components/ModalManagementDetails";

const ReportOnBot = () => {
  const [selectedRange, setSelectedRange] = useState('today');
  const [chartData, setChartData] = useState({ series: [], options: {} });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(''); // Para identificar el contenido de la tabla a mostrar
  const [data, setData] = useState({ gestiones: [], gestiones_fuera_bot_list: [] }); // Aquí guardamos los datos
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const getReportData = async (range) => {
    const today = new Date();
    let fechaInicio;
    let fechaFin;

    if (range === 'lastWeek') {
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      fechaInicio = lastWeek.toISOString().split('T')[0];
      fechaFin = today.toISOString().split('T')[0];
    } else {
      fechaInicio = today.toISOString().split('T')[0];
      fechaFin = today.toISOString().split('T')[0];
    }

    const response = await getReportsBot(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=&agents=&campaign=`);
    const responseData = response.data;
    setData(responseData); // Guardamos los datos para las tablas
    transformDataForChart(responseData);
  };

  useEffect(() => {
    getReportData(selectedRange);
    // eslint-disable-next-line 
  }, [selectedRange]);

  const transformDataForChart = (data) => {
    const { gestiones_en_bot, gestiones_fuera_bot } = data;
    const series = [gestiones_en_bot, gestiones_fuera_bot];
    const options = {
      labels: ['Conversaciones en Bot', 'Conversaciones fuera del Bot'],
      colors: ['#0088FE', '#FF8042'],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '18',
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, { dataPointIndex }) => {
            setModalContent(dataPointIndex === 0 ? 'gestiones' : 'gestiones_fuera_bot_list');
            toggleModal();
          }
        }
      }
    };

    setChartData({ series, options });
  };
  const handleGestionDetallada = async (id) => {
    const response = await getGestionesContactoRead(id)
    setGestionDetallada(response.data)
    setDetallesModal(true)
  }
  const renderTableContent = () => {
    const gestiones = modalContent === 'gestiones' ? data.gestiones : data.gestiones_fuera_bot_list;
    const tableTitle = modalContent === 'gestiones' ? "Conversaciones en Bot" : "Conversaciones fuera del Bot";

    return (
      <Table className="table table-sm text-center mb-3 font-size-15" hover responsive>
        <thead className="table-dark align-middle">
          <tr>
            <th colSpan="6" className="text-white font-size-18">{tableTitle}</th>
          </tr>
          <tr>
            <th>Contacto</th>
            <th>Campaña</th>
            <th>Estado</th>
            <th>Fecha</th>
            <th>Canal de Comunicación</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {gestiones && gestiones.map((gestion) => (
            <tr key={gestion.id}>
              <td>{gestion.contacto}</td>
              <td>{gestion.campana ? gestion.campana.map(item => item[0]) : null}</td>
              <td>{gestion.estado}</td>
              <td>{gestion.created_date}</td>
              <td>{gestion.type}</td>
              <td>
                <Link to="#" onClick={() => handleGestionDetallada(gestion.id)}>
                  <span className="fas fa-eye fa-lg"></span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <Card
        className="px-4 py-3"
        style={{ backgroundColor: "#f6f8fc", borderColor: "#f6f8fc", boxShadow: "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)" }}
      >
        {detallesModal ? (
          <ModalManagementDetails
            isOpen={detallesModal}
            onCloseClick={() => setDetallesModal(false)}
            GestionDetallada={GestionDetallada}
          ></ModalManagementDetails>
        ) : null}
        <div className="d-flex justify-content-between ">
          <p className="fw-semibold font-size-18 ">Ingresados al Bot</p>
          <select
            style={{ maxWidth: "170px", maxHeight: "34px", backgroundColor: "#f6f8fc" }}
            className="form-select font-size-13"
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
          >
            <option value="lastWeek">Última Semana</option>
            <option value="today">Hoy</option>
          </select>
        </div>
        <ResponsiveContainer width="100%" height={280}>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            className="apex-charts"
            dir="ltr"
          />
        </ResponsiveContainer>
        <div className="p-1 d-flex justify-content-end">
          <Link to="report_for_bot" className="btn btn-success btn-sm">
            Consultar reportes
          </Link>
        </div>
      </Card>

      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalBody>
          {renderTableContent()}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportOnBot;
