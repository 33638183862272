import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Nav, NavItem, NavLink,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import excelLogo from "../../assets/images/excel.png"
import whatsapp from "../../assets/images/whatsapp.png"
import classnames from 'classnames';
import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { leadsFilter } from "../../store/actions";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getAgent, getCampaignFullList, postExcelLeads, postLeadsStatusChange } from "../../helpers/backend";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ManagementManual from "../Whatsapp/components/ManagementManual";
import ManualProgrammingForm from "./components/ManualProgrammingForm";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";
import TimeLine from "./components/TimeLine";
import BroadcastCampaign from "../BroadcastCampaign/BroadcastCampaign";

function Leads(props) {
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);
  const dispatch = useDispatch()
  const history = useHistory()
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState('');
  const [agente, setAgente] = useState('');
  const [campaña, setCampaña] = useState('');
  const [agent, setAgent] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [manualOpen, setManualOpen] = useState(false);
  const [manualProgrammingOpen, setManualProgrammingOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(null)
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)
  const [detallesLead, setDetallesLead] = useState("")
  const [detallesOpen, setDetallesOpen] = useState(false)
  const [detallesModal, setDetallesModal] = useState(false);
  const [modalTimeLine, setModalTimeLine] = useState(false);
  const [gestionLead, setGestionLead] = useState(null);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [contactId, setContactId] = useState(null)
  const [leadId, setLeadId] = useState(null)

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleDetallesModal = () => {
    setDetallesModal(false);
  };
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (activeTab === "1") {
      dispatch(leadsFilter(`?tipo_lead=event&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña} `));
    }
    else {
      dispatch(leadsFilter(`?tipo_lead=programado&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña} `));
    }
  };
  const resetSearch = () => {
    setActiveTab("1")
    dispatch(leadsFilter(`?tipo_lead=event`));
    setFechaFin("");
    setFechaInicio("");
    setSelectedAgents("");
    setSelectedCampaign("");
  };

  const getData = (lead) => {
    dispatch(leadsFilter(`?tipo_lead=${lead}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña} `))

  }

  const handleRefresh = () => {
    setActiveTab('2')
    dispatch(leadsFilter(`?tipo_lead=programado&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña} `))

  }

  useEffect(() => {
    dispatch(leadsFilter(`?tipo_lead=event&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña} `))
    getAgentData();
    getCampaignData();
    // eslint-disable-next-line
  }, [estado]);

  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const postExcel = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const response = await postExcelLeads(formData)
    setLoading(false)
    setStatus(response.status)
    setData(response.data)
    setLoading(false)
    setAlert(true)
  };

  const handleStatusChange = async (lead) => {
    const response = await postLeadsStatusChange(lead, {
      accion: "INICIAR"
    })
    console.log(response);
  }

  const StatusBadge = ({ estado }) => {
    const estadoConfig = {
      FINALIZADO: { text: "Finalizado", className: "bg-success" },
      PENDIENTE: { text: "Pendiente", className: "bg-danger" },
      SIN_ASIGNAR: { text: "Sin Asignar", className: "bg-warning" },
      EN_PROCESO: { text: "En Proceso", className: "", style: { background: "rgb(111, 66, 193)" } },
    };
    const config = estadoConfig[estado] || {};
    return (
      <span className={`badge ${config.className}`} style={config.style}>
        {config.text}
      </span>
    );
  };

  const params = {
    count: props.leads.count,
    next: props.leads.next,
    previous: props.leads.previous,
    results: props.leads.results,
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>TrueContact | Leads </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="TrueContact" breadcrumbItem=" Leads " />
        {loading ? (
          <Loader />
        ) : null}
        {status === 200 && alert ? (
          <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
              setAlert(false)
              setSelectedFile("")
            }}
          >{data.message}
          </SweetAlert>
        ) : null}
        {status !== 200 && alert ? (
          <SweetAlert
            title="Error"
            danger
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
              setAlert(false)
            }}
          > {data.error}
          </SweetAlert>
        ) : null}
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Modal
              isOpen={detallesOpen}
              toggle={() => setDetallesOpen(false)}
              centered={true}
            >
              <div className="modal-header">
                <h4 className="mt-2">Detalles Adicionales</h4>
                <button
                  type="button"
                  onClick={() => setDetallesOpen(false)}
                  className="btn-close btn-sm"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className='p-3'>
                <p>{detallesLead}</p>
              </div>

              <Row className="mt-3 p-2">
                <Col className="d-flex justify-content-end">
                  <Button className="text-dark btn btn-secondary"
                    onClick={() => setDetallesOpen(false)}
                  >
                    Cerrar
                  </Button>
                </Col>
              </Row>

            </Modal>
            <Card>
              <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col>
                        <Input
                          type="date"
                          className="form-control me-1"
                          name="fechaInicio"
                          placeholder="Fecha Inicio"
                          value={fechaInicio}
                          onChange={(e) => setFechaInicio(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="date"
                          className="form-control me-1"
                          name="fechaFin"
                          placeholder="Fecha Fin"
                          value={fechaFin}
                          onChange={(e) => setFechaFin(e.target.value)}
                        />
                      </Col>
                      {groups.includes("SuperAdmin") || groups.includes("Supervisor") ?
                        <>
                          <Col>
                            <Select
                              className="me-1"
                              options={Array.isArray(agent)
                                ? agent.map((item) => ({
                                  label: item.get_full_name,
                                  value: item.id,
                                }))
                                : []}
                              value={selectedAgents}
                              onChange={handleSelectChange}
                              isMulti={true}
                              placeholder="Agentes"
                              menuPosition="fixed"
                            />
                          </Col>
                          <Col>
                            <Select
                              className=" me-1"
                              options={Array.isArray(campaignData)
                                ? campaignData.map((item) => ({
                                  label: item.name,
                                  value: item.id,
                                }))
                                : []}
                              onChange={handleSelectCampaignChange}
                              value={selectedCampaign}
                              menuPosition="fixed"
                              placeholder="Campañas"
                            />
                          </Col>
                        </>
                        : null}
                      <Col>
                        <Button color="warning" className="btn-sm me-1 mt-1" onClick={onSubmit}>
                          <b>Consultar</b>
                        </Button>
                        <Button color="danger" className="btn-sm me-1 mt-1" onClick={resetSearch}>
                          <b>Borrar</b>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/*BOTONES DE FILTROS*/}
            <Row className="mb-3">
              <Col className="d-flex justify-content-center mb-2">
                <Button color="pink" style={{ boxShadow: estado === "" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} className="btn-sm bg-pink text-white ms-1" onClick={() => setEstado("")}><b>Todas</b> </Button>
                <Button color="warning" className="btn-sm bg-warning  ms-1" style={{ boxShadow: estado === "SIN_ASIGNAR" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} onClick={() => setEstado("SIN_ASIGNAR")}><b>Sin Asignar</b> </Button>
                <Button className="btn-sm btn-danger ms-1" style={{ boxShadow: estado === "PENDIENTE" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} onClick={() => setEstado("PENDIENTE")}><b>Pendiente</b> </Button>
                <Button color="#6f42c1" className="btn-sm text-white ms-1" style={{ background: "#6f42c1", boxShadow: estado === "EN_PROCESO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} onClick={() => setEstado("EN_PROCESO")}><b>En Proceso</b> </Button>
                <Button className="btn-sm btn-success ms-1" style={{ boxShadow: estado === "FINALIZADO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} onClick={() => setEstado("FINALIZADO")}><b>Finalizado</b> </Button>
              </Col>
            </Row>
            {manualOpen &&
              <ManagementManual isLead={true} isOpen={manualOpen} onClose={() => setManualOpen(false)} contactId={contactId} leadId={leadId} />}
            {manualProgrammingOpen &&
              <ManualProgrammingForm isOpen={manualProgrammingOpen} onClose={() => setManualProgrammingOpen(false)} refresh={handleRefresh} />}
            {detallesModal &&
              <ModalManagementDetails isOpen={detallesModal} onCloseClick={handleDetallesModal} GestionDetallada={GestionDetallada} />}
            {modalTimeLine &&
              <TimeLine isOpen={modalTimeLine} onClose={() => setModalTimeLine(false)} GestionLead={gestionLead} />}
            <Card className="mx-auto  mt-3">
              <Nav tabs justified className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '1' })}`}
                    onClick={() => { toggle('1'); getData("event") }}
                    style={{ cursor: 'pointer' }}>
                    <i className="fas fa-user-alt" style={{ fontSize: "18px" }}></i>
                    <span className="d-sm-block" style={{ fontSize: "15px" }}>LEADS</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '2' })}`}
                    onClick={() => { toggle('2'); getData("programado") }}
                    style={{ cursor: 'pointer' }}>
                    <i className="fas fa-calendar-alt" style={{ fontSize: "18px" }}></i>
                    <span className="d-sm-block" style={{ fontSize: "15px" }}>PROGRAMACIONES</span>
                  </NavLink>
                </NavItem>
                {groups.includes("SuperAdmin") || groups.includes("Supervisor") ?
                <NavItem>               
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '3' })}`}
                    onClick={() => { toggle('3') }}
                    style={{ cursor: 'pointer' }}>
                    <i className="fas fa-mail-bulk" style={{ fontSize: "18px" }}></i>
                    <span className="d-sm-block" style={{ fontSize: "15px" }}>DIFUSIÓN</span>
                  </NavLink>
                </NavItem>
                : null}
              </Nav>
              {activeTab === '1' && (
                params.count === 0 ? (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <div className="table-responsive mt-3">
                      <Table className="table mb-4 text-center" hover responsive>
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Origen</th>
                            <th>Creación</th>
                            <th>Nombre</th>
                            <th>Estado</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Campaña</th>
                            <th>Agente</th>
                            <th></th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody className="align-middle">
                          {params.results && params.results.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item.gestion_leads && item.gestion_leads.length > 0 ?
                                  <Link to="#" onClick={() => { setModalTimeLine(true); setGestionLead(item.gestion_leads) }}> <b> {index + 1}</b></Link>
                                  : <b> {index + 1}</b>}
                              </td>
                              <td>{item.source}</td>
                              <td>{item.created_date}</td>
                              <td>{item.contact && item.contact.first_name}</td>
                              <td><StatusBadge estado={item.estado} />
                                {item.estado === "FINALIZADO" && item.gestion_contacto ?
                                  <Link to="#">
                                    <span
                                      className="fas fa-eye fa-md mx-1 "
                                      onClick={() => {
                                        setDetallesModal(true);
                                        setGestionDetallada(item.gestion_contacto);
                                      }} >
                                    </span>
                                  </Link>
                                  : null}</td>
                              <td>{item.contact && item.contact.phone[0]?.telefono}</td>
                              <td>{item.contact && item.contact.email[0]?.email}</td>
                              <td>{item.campaign}</td>
                              <td>{item.agent && item.agent[0] ? item.agent[0][0] : null}</td>
                              <td><Link to="#" onClick={() => {
                                setDetallesLead(item.metadata); setDetallesOpen(true)
                              }}> Ver Detalles
                              </Link>
                              </td>
                              <td>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    window.focus();
                                    history.replace({
                                      pathname: '/chat',
                                      state: {
                                        selectedChatId: item.contact && item.contact.phone[0]?.telefono,
                                        contactId: item.contact && item.contact.id, type: "WhatsApp", is_lead: true, leadId: item.id,
                                      }
                                    });
                                    handleStatusChange(item.id)
                                  }}>
                                  <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                                </span>{" "}
                                <Link to="#" onClick={() => {
                                  setManualOpen(true);
                                  setContactId(item.contact ? item.contact.id : null);
                                  setLeadId(item.id);
                                  handleStatusChange(item.id)
                                }}>
                                  <span className="fa fa-phone fa-md align-middle" style={{ fontSize: "20px" }}></span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="leads/leads/"
                      filterFunction={leadsFilter}
                    />
                  </CardBody>
                )
              )}
              {activeTab === '2' && (
                <>
                  <Col>
                    <div className="m-2">
                      <input
                        type="file"
                        id="excelFile"
                        accept=".xls, .xlsx"
                        style={{ display: 'none' }}
                        onChange={(event) => setSelectedFile(event.target.files[0])}
                      />
                      <div className="d-flex align-items-center mb-1">
                        <Button
                          color="success"
                          className="me-2 btn-sm"
                          onClick={() => {
                            document.getElementById('excelFile').click();
                          }}
                        >
                          <span>
                            <img className="me-1" alt="" src={excelLogo} height="20px" />
                          </span>
                          <b>Subir Programaciones</b>
                        </Button>
                        <Button
                          color="info"
                          className="btn-sm"
                          onClick={() => { setManualProgrammingOpen(true) }}
                        >
                          <b>Programación Manual</b>
                        </Button>
                      </div>
                      {selectedFile && (
                        <p className="font-size-10 ms-3 mb-1">
                          Archivo seleccionado: {selectedFile.name}
                        </p>
                      )}
                      {selectedFile && (
                        <>
                          <Button
                            color="success"
                            className="mb-2 ms-2 btn-sm"
                            onClick={() => { setLoading(true); postExcel() }}
                          >
                            Generar
                          </Button>
                          <Button
                            color="danger"
                            className="mb-2 ms-2 btn-sm"
                            onClick={() => {
                              setSelectedFile(null);
                            }}
                          >
                            Borrar
                          </Button>
                        </>
                      )}
                    </div>
                  </Col>
                  {params.count === 0 ? (
                    <CardBody>
                      <h4 className="card-title">No se encontraron resultados</h4>
                    </CardBody>
                  ) : (
                    <CardBody>
                      {!selectedFile && (
                        <>
                          <div className="table-responsive mt-1">
                            <Table className="table mb-4 text-center" hover responsive>
                              <thead className="table-dark align-middle">
                                <tr>
                                  <th>Nombre</th>
                                  <th>Estado</th>
                                  <th>Teléfono</th>
                                  <th>Email</th>
                                  <th>Campaña</th>
                                  <th>Agente</th>
                                  <th>Vencimiento</th>
                                  <th></th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {params.results && params.results.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.contact && item.contact.first_name}</td>
                                    <td><StatusBadge estado={item.estado} />
                                      {item.estado === "FINALIZADO" && item.gestion_contacto ?
                                        <Link to="#">
                                          <span
                                            className="fas fa-eye fa-lg"
                                            onClick={() => {
                                              setDetallesModal(true);
                                              setGestionDetallada(item.gestion_contacto);
                                            }} >
                                          </span>
                                        </Link>
                                        : null}
                                    </td>
                                    <td>{item.contact && item.contact.phone[0]?.telefono}</td>
                                    <td>{item.contact && item.contact.email[0]?.email}</td>
                                    <td>{item.campaign}</td>
                                    <td>{item.agent && item.agent[0] ? item.agent[0][0] : null}</td>
                                    <td>{item.fecha_vencimiento}</td>
                                    <td><Link to="#" onClick={() => { setDetallesLead(item.metadata ? item.metadata + item.observaciones : "" + item.observaciones); setDetallesOpen(true) }}> Ver Detalles
                                    </Link></td>
                                    <td>
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          window.focus();
                                          history.push({
                                            pathname: '/chat',
                                            state: {
                                              selectedChatId: item.contact && item.contact.phone[0]?.telefono,
                                              contactId: item.contact && item.contact.id, type: "WhatsApp", is_lead: true, leadId: item.id
                                            }
                                          });
                                          handleStatusChange(item.id)
                                        }}>
                                        <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                                      </span>{" "}
                                      <Link to="#" onClick={() => {
                                        setManualOpen(true);
                                        setContactId(item.contact ? item.contact.id : null);
                                        setLeadId(item.id)
                                        handleStatusChange(item.id)
                                      }}>
                                        <span className="fa fa-phone fa-md align-middle" style={{ fontSize: "20px" }}></span>
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <Pagination
                            count={params.count}
                            next={params.next}
                            previous={params.previous}
                            limit={30}
                            baseUrl="leads/leads/"
                            filterFunction={leadsFilter}
                          />
                        </>)}
                    </CardBody>
                  )}
                </>)}
              {activeTab === '3' && (

                <BroadcastCampaign />

              )}
            </Card>
            <Row>
              <Col className="d-flex justify-content-center">
                <Link className="btn btn-secondary text-dark mb-5 " to='/dashboard'>
                  Volver
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, leads } = state.Leads;
  return { error, loading, leads };
};

export default withRouter(connect(mapStateToProps)(Leads));

